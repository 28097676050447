<template>
<CCard style="min-height: 120px;">
    <CCardHeader class="pt-2 pb-0">
        <CButton v-if="canEditContent" class="float-right btn text-left" color="danger" size="sm" :disabled="isLoading || isError"
                @click="onDelete"
        ><CIcon name="cil-trash" class="save-icon mr-1" />Delete Page</CButton>
        <div class="float-left d-flex flex-row mt-1 mb-1" style="width:auto">
            <CIcon name="cil-spreadsheet" size="lg" class="mr-2 page-icon" /><h5 class="">Content Page: {{ pageName }}</h5>
        </div>
    </CCardHeader>
    <CCardBody class="pt-3 pb-2">
        <CModal
        title="Discard changes?"
        color="dark"
        :show.sync="showModal"
        >
        It looks like you're in the middle of writing or changing something and haven't saved all of your content.
        Do you want to proceed and discard the changes?
        <template #footer>
            <CButton @click="onCancel">Cancel</CButton>
            <CButton color="danger" @click="onDiscard">Discard</CButton>
       </template>
        </CModal>
        <CModal
        title="Delete the page?"
        color="danger"
        :show.sync="showDeleteModal"
        >
        Do you really want to delete the whole page<span v-if="isMultiLang"> including all languages</span>?
        If you delete the page, make sure to remove all links as well!
        <template #footer>
            <CButton @click="showDeleteModal = false">Cancel</CButton>
            <CButton color="danger" @click="onConfirmDelete">Yes</CButton>
       </template>
        </CModal>
        <CModal
        title="Remove Translation?"
        color="danger"
        :show.sync="showRemoveModal"
        >
        Do you want to remove the translation <strong>'{{selectedLang}}'</strong> and display the default page instead?
        <template #footer>
            <CButton @click="showRemoveModal = false">Cancel</CButton>
            <CButton color="danger" @click="onConfirmRemove">Yes</CButton>
       </template>
        </CModal>
        <CRow v-if='isLoading'>
            <CCol lg="12" class="justify-content-center">
                <CSpinner class="mt-2" />
            </CCol>
        </CRow>
         <CAlert color="danger" class="mb-1 mt-1 mb-3" v-if="!isLoading && isError">
            An error occurred. <CLink class="strong" color="danger" to="/pages">Click here</CLink> to return to the content pages list.
        </CAlert>
        <CTabs ref="tabs" v-if="isMultiLang && !isLoading" addTabsWrapperClasses="pl-0 pr-0 pt-1 mt-2"
               :activeTab.sync="activeTranslationTab"
               @update:activeTab="onTabChanged"
        >
            <CTab v-for="tl in translations" :key="tl.language" addClasses="info" class="pt-0">
                <template slot="title">
                    <span :class="[`lang-${tl.language}`,tl.isMissing ? 'text-warning' : null]">{{ getLangName(tl.language) }}</span>
                </template>
                 <template>
                    <CAlert color="warning" class="mb-2 mt-0" v-if="tl.language==''">
                        The <em>Default</em> page content is used for the following language(s): <strong>{{ missingText }}</strong>
                    </CAlert>
                    <CAlert color="warning" class="mb-2 mt-0" v-if="tl.language!='' && tl.isMissing">
                        If you leave this empty, the default page content will be used
                    </CAlert>
                    <CRow class="pl-1 mb-2" v-if="tl.language!='' && !tl.isMissing">
                        <CCol sm="12" >
                            <label class="strong">Translation:</label> {{ tl.language }}
                            <CBadge v-if="canEdit"
                                    class="ml-1 p-1 pr-2" color="danger" size="lg" style="cursor: pointer"
                                    @click="onRemoveTranslation(tl.language)"
                            ><CIcon name="cilX" size="sm" class="mr-1" />Remove</CBadge>
                        </CCol>
                    </CRow>
                 </template>
            </CTab>
        </CTabs>
        <template  v-if='!isLoading && !isError'>
        <CRow class="mt-0 pl-1">
            <CCol sm="12">
                <CInput
                label="Page Title"
                placeholder="Enter the page title"
                v-model="title"
                :isValid="isTitleValid"
                :readonly="!canEdit"
                />
            </CCol>
        </CRow>
        <CRow class="pl-1">
            <CCol sm="12">
                <label>Page Content <span v-if="contentKind == 'Html'" class="strong text-info">[Raw HTML]</span></label>
                &nbsp;<span v-if="contentKind == 'Html'" class="text-danger">Please be careful and make sure to only input valid HTML</span>
                <quill-editor
                    ref="editor"
                    :disabled="!canEdit"
                    :options="quillOptions"
                    @change="onEditorChange($event)"
                />
            </CCol>
        </CRow>
        <div class="float-right">
            <CButton class="mt-2 mb-2 btn" color="secondary" size="sm" :disabled="!hasChanges"
                    @click="onReset"
            ><CIcon name="cilActionUndo" size="sm" class="mr-1" style="transform: rotate(90deg);" /> Reset</CButton>
            <CButton v-if="canEdit" class="ml-2 mt-2 mb-2 btn" color="success" size="sm" :disabled="!hasChanges"
                    @click="onSave"
            ><CIcon name="cil-save" class="save-icon mr-1" size="sm" />Save Page</CButton>
        </div>
        </template>
    </CCardBody>
</CCard>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { actionNames } from '@/store/modules/content/actions';

import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { EDIT_CONTENT, EDIT_HTML, mapPermissions } from '@/permissions';

Vue.use(VueQuillEditor)

export default {
    props: {
        pageName: String,
        lang: String,
        kind: String
    },
    data() {
        return {
            isError: false,
            activeTranslationTab: 0,
            prevTab: 0,
            title: null,
            selectedLang: '',
            hasChanges: false,
            showModal: false,
            showDeleteModal: false,
            showRemoveModal: false,
            contentKind: "Html"
        }
    },
    computed: {
        ...mapPermissions({
            canEditContent: EDIT_CONTENT,
            canEditHtml: EDIT_HTML
        }),
        canEdit() {
            return this.canEditContent && (this.contentKind != "Html" || this.canEditHtml);
        },
        ...mapGetters({
            page: 'content/page',
            isLoading: 'content/loading',
            translations: 'content/translations',
            merchantId: 'merchantId'
        }),
        empty()
        {
            return this.page != null;
        },
        isMultiLang()
        {
            return this.translations != null && this.translations.filter(l => l.language != "").length > 1;
        },
        isTranslation() {
            return this.isMultiLang && this.selectedLang != '' && (this.selectedLang);
        },
        missingText() {
            return this.translations.filter(t => t.isMissing && t.language != '').map(o => o.language).join (', ');
        },
        isTitleValid()
        {
            if (this.title == null || this.title == "") {
                return false;
            }

            const match = this.title.match(/^.*[^\s]+.*$/g);
            return !!(this.title && (this.title.length < 100) && match && match && (this.title == match[0]));
        },
        quillOptions() {
          if (this.contentKind == "Html") {
            return {
                placeholder: 'Enter HTML content ...',
                modules: { toolbar: false }
            }
          }
          else {
            return {
                placeholder: 'Enter page content ...',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'indent': '-1'}, { 'indent': '+1' }],
                        ['link'],
                        ['clean']
                    ]
                }
            }
          }
      }
    },
    methods: {
        ...mapActions({
            loadPage: actionNames.LOAD_PAGE,
            save: actionNames.SAVE_PAGE,
            delete: actionNames.DELETE_PAGE,
            removeTranslation: actionNames.REMOVE_TRANSLATION
        }),
        async load({name,language}) {
            this.isError = false;
            this.hasChanges = false;
            try {
                await this.loadPage({name,language});
            }
            catch (e)
            {
                this.isError = true;
            }
            try {
                this.setPage(this.page);
            }
            catch (e)
            {
                this.isError = true;
            }
            const o = this;
            setTimeout(function() {
                o.hasChanges = false;
            }, 1);
            this.hasChanges = false;

            if (!this.isError) {
                const current = this.translations.find(t => t.language == language);
                this.selectedLang = language;
                if (!current) {
                    this.activeTranslationTab = 0;
                }
                else {
                    this.activeTranslationTab = this.translations.indexOf(current);
                }
            }
        },
        getLangName(l) {
            return !l ? 'Default' : l;
        },
        setPage(page) {
            const editor = this.$refs.editor.quill;
            switch (page.kind) {
                case 'Html':
                    editor.setText(page.content || '');
                    break;
                case 'Delta':
                    editor.setContents(JSON.parse(page.content));
                    break;
                case 'Text':
                    editor.setText(page.content);
                    break;
            }
            this.title = page.title;
            this.contentKind = page.kind;
            this.hasChanges = false;
        },
        changeTabTo(index) {
            this.activeTranslationTab = index;
            const tl = this.translations[index];
            this.selectedLang = tl ? tl.language : '';
        },
        /* eslint-disable */
        onEditorChange(ev) {
            this.hasChanges = true;
        },
        onTabChanged(index)
        {
            if (this.hasChanges)
            {
                this.newTabIndex = index;
                this.showModal = true;
            }
            else {
                const tl = this.translations[index];
                this.selectedLang = tl ? tl.language : '';
            }
        },
        onDiscard() {
            this.hasChanges = false;
            this.changeTabTo(this.newTabIndex);
            this.showModal = false;
        },
        onCancel() {
            this.activeTranslationTab = this.prevTab;
            this.showModal = false;
        },
        onReset()
        {
            this.load({name:this.pageName, language: this.selectedLang});
        },
        async onRemoveTranslation()
        {
            this.showRemoveModal = true;
        },
        async onConfirmRemove() {
            this.showRemoveModal = false;
            await this.removeTranslation({name: this.pageName, language: this.selectedLang})
            this.hasChanges = false;
            this.changeTabTo(0);
        },
        async onSave() {
            if (!this.isTitleValid)
                return false;

            const editor = this.$refs.editor.quill;

            let content;
            let delta = null;
            if (this.contentKind == "Delta") {
              delta = editor.getContents();
              content = JSON.stringify(delta);
            }
            else {
              content = editor.getText();
            }
            let success = true;
            try {
                await this.save({
                    name: this.pageName,
                    language: this.selectedLang,
                    title: this.title,
                    content,
                    kind: this.contentKind
                });
            }
            catch (e) {
                success = false;
            }
            const o = this;
            setTimeout(function() {
                  if (o.contentKind == "Delta") {
                    o.$refs.editor.quill.setContents(delta);
                  }
                  else {
                    o.$refs.editor.quill.setText(content);
                  }
                  if (success) {
                    o.hasChanges = false;
                  }
            }, 20);
        },
        onDelete()
        {
            this.showDeleteModal = true;
        },
        async onConfirmDelete() {
            this.showDeleteModal = false;
            try {
                await this.delete({name:this.pageName});
            }
            catch {
                console.debug("FAIL");
            }
            this.$router.replace('/pages');
        }
    },
    watch: {
        activeTranslationTab(newValue, oldValue) {
            this.prevTab = oldValue;
        },
        selectedLang(lang, oldLang) {
            const tl = this.translations[lang];
            this.load({name:this.pageName, language: lang});
        },
        title(newVal, oldVal) {
            if (newVal != oldVal)
            {
                this.hasChanges = true;
            }
        }
    },
    created() {
        this.contentKind = this.kind;
        this.load({name:this.pageName, language: this.lang ? this.lang : ""});
    }
}
</script>

<style scoped>
.page-icon {
    transform: translateY(1px);
}
.save-icon {
    transform: translateY(-2px);
}
.btn {
    width: 105px;
}
</style>
